import React from "react"
import { Link } from "gatsby"
import slugify from "slugify"
import { BsPersonSquare } from "react-icons/bs"
import "./articleList.scss"

const ArticleListItem = ({
  idx,
  link,
  banner,
  categories,
  title,
  excerpt,
  authorImage,
  author,
  publishedAt,
}) => {
  return (
    <div className="row mb-4" key={idx}>
      <div className="col-12">
        <div className="blog-item">
          <Link to={`/${link}/`}>
            <div className="article-banner-wrapper">
              <div
                className="article-banner"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              />
            </div>
          </Link>
          <div className="blog-text py-5 px-4">
            <div className="font-weight-bold">
              {categories !== null
                ? categories.map((cat, catIdx) => (
                    <>
                      <Link
                        to={`/category/${slugify(cat.title.toLowerCase())}/`}
                        key={catIdx}
                        className="article-category"
                      >
                        {cat.title}
                      </Link>
                      {categories.length > 1 ? (
                        categories.length === catIdx + 1 ? null : (
                          <>,&nbsp;</>
                        )
                      ) : null}
                    </>
                  ))
                : null}
            </div>

            <Link to={`/${link}/`} className="article-title-main">
              {title}
            </Link>

            <p className="article-excerpt my-3">{excerpt}</p>
            <span className="article-date">{publishedAt}</span>
            <div className="article-footer my-4">
              <div className="d-flex align-items-center no-gutters">
                <div>
                  {authorImage !== null ? (
                    <img
                      src={authorImage}
                      className="img-fluid rounded-circle"
                      alt=""
                    />
                  ) : (
                    <BsPersonSquare size="2rem" />
                  )}
                </div>
                <div className="ps-3">
                  <Link
                    to={`/author/${slugify(author).toLowerCase()}/`}
                    className="article-author"
                  >
                    {author}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleListItem
