import React from "react"
import { Link, navigate } from "gatsby"
import PageItem from "react-bootstrap/PageItem"

export const Pagination = ({ pageContext, basePath }) => {
  if (!pageContext) return null
  const { previousPagePath, nextPagePath, numberOfPages, humanPageNumber } =
    pageContext
  const bastPathDefault = basePath ?? "/blog"
  let items = []

  Array.from({ length: numberOfPages }, (_, i) => {
    const page = i + 1
    items.push(
      <PageItem
        key={page}
        active={page === humanPageNumber}
        onClick={() => {
          if (page === humanPageNumber) return
          let pageNum = page === 1 ? "" : page
          navigate(`${bastPathDefault}/${pageNum}`)
        }}
      >
        {page}
      </PageItem>
    )
  })

  console.log("previousPagePath", previousPagePath)

  return (
    <>
      <div className="d-md-none">
        <nav style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {previousPagePath && (
              <Link className="btn btn-primary" to={previousPagePath + "/"}>
                <span>← Newer Posts</span>
              </Link>
            )}
          </div>
          <div style={{ justifySelf: "flex-end" }}>
            {nextPagePath && (
              <Link className="btn btn-primary" to={nextPagePath + "/"}>
                <span>Older Posts →</span>
              </Link>
            )}
          </div>
        </nav>
      </div>
      <div className="mt-5 d-none d-md-block">
        <ul className="justify-content-center pagination pagination-lg">
          {items}
        </ul>
      </div>
    </>
  )
}
