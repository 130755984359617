import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./heading.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function PageHeading({ heading, subheading, BgImg }) {
  return (
    <Container
      fluid
      className="page-heading"
      style={{ backgroundImage: `url(${BgImg})` }}
    >
      <Container>
        <Row>
          <Col lg="8" className="mx-auto text-center text-white">
            {" "}
            <h1
              className="page-hero-heading text-uppercase display-5 pb-3"
              dangerouslySetInnerHTML={{
                __html: heading,
              }}
            />
            {subheading !== undefined ? (
              <p className="page-hero-subheading">{subheading}</p>
            ) : null}
          </Col>
        </Row>
        <StaticImage src="../../images/stick.webp" 
        alt="stick" className="stick" loading="eager" />
      </Container>
    </Container>
  )
}
