import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from "../components/heading/page-heading"
import ArticleListItem from "../components/articleList/articleListItem"
import { getSrc } from "gatsby-plugin-image"

// images
import HeadingBg from "../images/slide3.jpg"
import placeholder from "../images/place-holder.webp"
import { graphql, navigate } from "gatsby"
import { Pagination } from "../components/pagination"
import { Form } from "react-bootstrap"

class BlogsEsTemplate extends React.Component {
  render() {
    const posts = this.props.data.allSanityPost.edges.map((t) => t.node)
    const navigateToPage = (event) => {
      const selected = event.target.value
      if (selected === "en") {
        navigate("/blog#container-template")
      }
    }

    return (
      <Layout>
        <SEO
          title={"Bay Street Blogs"}
          description={
            "Bay Street Capital Holdings is an independent investment advisory, wealth management, and financial planning firm headquartered in Palo Alto, CA. We manage portfolios with the goal of maintaining and increasing total assets and income.  Although many financial advisors may focus on maximizing returns, we place a higher priority on managing total risk and volatility."
          }
          keyword={""}
        />
        <PageHeading heading={`Blog`} BgImg={HeadingBg} />
        <div id="container-template" className="container blogs-template">
          <div className="articles-list-wrapper">
            <div className="container">
              <div className="border-top py-110">
                <div className="d-flex justify-content-end px-4 mb-2">
                  <Form.Group>
                    <Form.Label className="py-1 px-2">
                      Select Translation
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control as="select" onChange={navigateToPage}>
                      <option value="en">English</option>
                      <option value="es" selected>
                        Spanish
                      </option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="mx-auto col-md-9 mb-5 mb-md-0">
                    {posts.map((article, idx) => (
                      <>
                        <ArticleListItem
                          key={idx}
                          idx={article.idx}
                          link={article.slug.current}
                          banner={
                            article?.mainImage !== null
                              ? getSrc(
                                  article?.mainImage?.asset?.gatsbyImageData
                                )
                              : placeholder
                          }
                          categories={
                            article.categories.length
                              ? article.categories
                              : null
                          }
                          title={article.title}
                          excerpt={
                            article.excerpt
                              ? article.excerpt.length > 70
                                ? `${article.excerpt.substring(0, 70)}...`
                                : article.excerpt
                              : article.videoUrl
                              ? article.videoUrl
                              : null
                          }
                          authorImage={
                            getSrc(
                              article?.authors?.image?.asset?.gatsbyImageData
                            )
                              ? getSrc(
                                  article?.authors?.image?.asset
                                    ?.gatsbyImageData
                                )
                              : null
                          }
                          author={article.authors.name}
                          publishedAt={article.publishedAt}
                        />
                      </>
                    ))}
                  </div>
                </div>

                <Pagination
                  pageContext={this.props.pageContext}
                  basePath="/blogs/es"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogsEsTemplate

export const blogsEsTemplateQuery = graphql`
  query blogsEsTemplateQuery($skip: Int!, $limit: Int!) {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { language: { eq: "es" } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          language
          slug {
            current
          }
          featured
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          videoUrl
          categories {
            id
            title
          }
          authors {
            name
            image {
              asset {
                gatsbyImageData(width: 50, height: 50)
              }
            }
          }
        }
      }
    }
  }
`
